import { environ } from "@ihr-radioedit/sdk-utils";
import axios from "axios";
import { isLeft } from "fp-ts/lib/Either";
import {
  CurrentConditions,
  CurrentConditionsResolver,
  Daypart,
  ForecastResult,
  ForecastResultResolver,
} from "@ihr-radioedit/inferno-core";
import { getPaths } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";

const log = ILog.logger("Weather");

interface WeatherQuery {
  baseUri: string;
  lat: string;
  lon: string;
  apiKey: string;
  tileLoader: boolean;
}

export const getCurrentConditions = async ({ lat, lon, baseUri, apiKey }: WeatherQuery) => {
  const params = new URLSearchParams();
  params.set("geocode", `${lat},${lon}`);
  params.set("format", "json");
  params.set("language", "en-US");
  params.set("units", "e");
  params.set("apiKey", apiKey);

  const response = await axios.get<CurrentConditions>(`${baseUri}/observations/current?${params.toString()}`, {
    headers: {
      "Accept-Encoding": "gzip",
    },
    timeout: parseInt(environ.get("REQUEST_TIMEOUT", "0"), 10),
  });

  const data = CurrentConditionsResolver.decode(response.data);
  if (isLeft(data)) {
    log.debug("Current Conditions decode errors:", JSON.stringify(getPaths(data), null, 4));
    throw new Error("Could not decode API response");
  }

  return data.right;
};

export const getSevenDayForecast = async ({ lat, lon, baseUri, apiKey, tileLoader }: WeatherQuery) => {
  const params = new URLSearchParams();
  params.set("geocode", `${lat},${lon}`);
  params.set("format", "json");
  params.set("language", "en-US");
  params.set("units", "e");
  params.set("apiKey", apiKey);

  const response = await axios.get<ForecastResult>(`${baseUri}/forecast/daily/7day?${params.toString()}`, {
    headers: {
      "Accept-Encoding": "gzip",
    },
    timeout: parseInt(environ.get("REQUEST_TIMEOUT", "0"), 10),
  });

  const data = ForecastResultResolver.decode(response.data);
  if (isLeft(data)) {
    log.debug("7 Day Weather Forecast decode errors:", JSON.stringify(getPaths(data), null, 4));
    throw new Error("Could not decode API response");
  }

  const forecastResult = data.right;
  const items: ForecastItem[] = [];

  let forecastStartDay = 1;
  let forecastEndDay = 8;

  if (tileLoader) {
    forecastStartDay = forecastStartDay - 1;
    forecastEndDay = forecastEndDay - 1;
  }

  for (let i = forecastStartDay; i < forecastEndDay; i++) {
    const item: ForecastItem = {
      dayOfWeek: forecastResult.dayOfWeek[i],
      expirationTimeUtc: forecastResult.expirationTimeUtc[i],
      moonPhase: forecastResult.moonPhase[i],
      moonPhaseCode: forecastResult.moonPhaseCode[i],
      moonPhaseDay: forecastResult.moonPhaseDay[i],
      moonriseTimeLocal: forecastResult.moonriseTimeLocal ? forecastResult.moonriseTimeLocal[i] : null,
      moonriseTimeUtc: forecastResult.moonriseTimeUtc ? forecastResult.moonriseTimeUtc[i] : 0,
      moonsetTimeLocal: forecastResult.moonsetTimeLocal ? forecastResult.moonsetTimeLocal[i] : "",
      moonsetTimeUtc: forecastResult.moonsetTimeUtc ? forecastResult.moonsetTimeUtc[i] : 0,
      narrative: forecastResult.narrative[i],
      qpf: forecastResult.qpf[i],
      qpfSnow: forecastResult.qpfSnow[i],
      sunriseTimeLocal: forecastResult.sunriseTimeLocal ? forecastResult.sunriseTimeLocal[i] : "",
      sunriseTimeUtc: forecastResult.sunriseTimeUtc ? forecastResult.sunriseTimeUtc[i] : 0,
      sunsetTimeLocal: forecastResult.sunsetTimeLocal ? forecastResult.sunsetTimeLocal[i] : "",
      sunsetTimeUtc: forecastResult.sunsetTimeUtc ? forecastResult.sunsetTimeUtc[i] : 0,
      temperatureMax: forecastResult.temperatureMax ? forecastResult.temperatureMax[i] : 0,
      temperatureMin: forecastResult.temperatureMin[i],
      validTimeUtc: forecastResult.validTimeUtc[i],
      validTimeLocal: forecastResult.validTimeLocal[i],
      calendarDayTemperatureMax: forecastResult.calendarDayTemperatureMax
        ? forecastResult.calendarDayTemperatureMax[i]
        : 0,
      calendarDayTemperatureMin: forecastResult.calendarDayTemperatureMin
        ? forecastResult.calendarDayTemperatureMin[i]
        : 0,
    };

    const daypart = forecastResult.daypart ? forecastResult.daypart[0] : null;

    if (daypart) {
      item.day = getForecastDaypart(daypart, [0, 2, 4, 6, 8, 10, 12, 14], i);
      item.night = getForecastDaypart(daypart, [1, 3, 5, 7, 9, 11, 13], i);
    }

    items.push(item);
  }
  return items;
};

export interface ForecastDaypart {
  cloudCover: number | null | undefined;
  dayOrNight: string | null | undefined;
  daypartName: string | null | undefined;
  iconCode: number | null | undefined;
  iconCodeExtend: number | null | undefined;
  narrative: string | null | undefined;
  precipChance: number | null | undefined;
  precipType: string | null | undefined;
  qpf: number | null | undefined;
  qpfSnow: number | null | undefined;
  qualifierCode: string | null | undefined;
  qualifierPhrase: string | null | undefined;
  relativeHumidity: number | null | undefined;
  snowRange: string | null | undefined;
  temperature: number | null | undefined;
  temperatureHeatIndex: number | null | undefined;
  temperatureWindChill: number | null | undefined;
  thunderCategory: string | null | undefined;
  thunderIndex: number | null | undefined;
  uvDescription: string | null | undefined;
  uvIndex: number | null | undefined;
  windDirection: number | null | undefined;
  windDirectionCardinal: string | null | undefined;
  windPhrase: string | null | undefined;
  windSpeed: number | null | undefined;
  wxPhraseLong: string | null | undefined;
  wxPhraseShort: string | null | undefined;
}

export interface ForecastItem {
  dayOfWeek: string;
  expirationTimeUtc: number;
  moonPhase: string;
  moonPhaseCode: string;
  moonPhaseDay: number | null | undefined;
  moonriseTimeLocal: string | null | undefined;
  moonriseTimeUtc: number | null | undefined;
  moonsetTimeLocal: string | null | undefined;
  moonsetTimeUtc: number | null | undefined;
  narrative: string;
  qpf: number;
  qpfSnow: number;
  sunriseTimeLocal: string | null | undefined;
  sunriseTimeUtc: number | null | undefined;
  sunsetTimeLocal: string | null | undefined;
  sunsetTimeUtc: number | null | undefined;
  temperatureMax: number | null | undefined;
  temperatureMin: number;
  validTimeUtc: number;
  validTimeLocal: string;
  calendarDayTemperatureMax: number | null | undefined;
  calendarDayTemperatureMin: number | null | undefined;
  day?: ForecastDaypart | null;
  night?: ForecastDaypart | null;
}

export const getForecastDaypart = (daypart: Daypart, lookup: number[], idx: number): ForecastDaypart | null => {
  if (daypart) {
    return {
      cloudCover: daypart.cloudCover ? daypart.cloudCover[lookup[idx]] : 0,
      dayOrNight: daypart.dayOrNight ? daypart.dayOrNight[lookup[idx]] : "",
      daypartName: daypart.daypartName ? daypart.daypartName[lookup[idx]] : "",
      iconCode: daypart.iconCode ? daypart.iconCode[lookup[idx]] : 0,
      iconCodeExtend: daypart.iconCodeExtend ? daypart.iconCodeExtend[lookup[idx]] : 0,
      narrative: daypart.narrative ? daypart.narrative[lookup[idx]] : "",
      precipChance: daypart.precipChance ? daypart.precipChance[lookup[idx]] : 0,
      precipType: daypart.precipType ? daypart.precipType[lookup[idx]] : "",
      qpf: daypart.qpf ? daypart.qpf[lookup[idx]] : 0,
      qpfSnow: daypart.qpfSnow ? daypart.qpfSnow[lookup[idx]] : 0,
      qualifierCode: daypart.qualifierCode ? daypart.qualifierCode[lookup[idx]] : "",
      qualifierPhrase: daypart.qualifierPhrase ? daypart.qualifierPhrase[lookup[idx]] : "",
      relativeHumidity: daypart.relativeHumidity ? daypart.relativeHumidity[lookup[idx]] : 0,
      snowRange: daypart.snowRange ? daypart.snowRange[lookup[idx]] : "",
      temperature: daypart.temperature ? daypart.temperature[lookup[idx]] : 0,
      temperatureHeatIndex: daypart.temperatureHeatIndex ? daypart.temperatureHeatIndex[lookup[idx]] : 0,
      temperatureWindChill: daypart.temperatureWindChill ? daypart.temperatureWindChill[lookup[idx]] : 0,
      thunderCategory: daypart.thunderCategory ? daypart.thunderCategory[lookup[idx]] : "",
      thunderIndex: daypart.thunderIndex ? daypart.thunderIndex[lookup[idx]] : 0,
      uvDescription: daypart.uvDescription ? daypart.uvDescription[lookup[idx]] : "",
      uvIndex: daypart.uvIndex ? daypart.uvIndex[lookup[idx]] : 0,
      windDirection: daypart.windDirection ? daypart.windDirection[lookup[idx]] : 0,
      windDirectionCardinal: daypart.windDirectionCardinal ? daypart.windDirectionCardinal[lookup[idx]] : "",
      windPhrase: daypart.windPhrase ? daypart.windPhrase[lookup[idx]] : "",
      windSpeed: daypart.windSpeed ? daypart.windSpeed[lookup[idx]] : 0,
      wxPhraseLong: daypart.wxPhraseLong ? daypart.wxPhraseLong[lookup[idx]] : "",
      wxPhraseShort: daypart.wxPhraseShort ? daypart.wxPhraseShort[lookup[idx]] : "",
    };
  }
  return null;
};
