import loadable from "@loadable/component";
import { inject } from "mobx-react";
import { useParams } from "react-router";

import type { PageBlockInterface } from "../../page-blocks/Block.component";
import { getSevenDayForecast } from "../../services/Weather";
import { Remote } from "../remote/Remote.component";
import { Container } from "../../ui";
import classnames from "classnames";
import type { Store } from "@inferno/renderer-shared-core";

const TrafficTile = loadable(() => import("./TrafficTile.component"));
const WeatherTile = loadable(() => import("./WeatherTile.component"));

interface WeatherTileLoaderProps extends PageBlockInterface {
  params?: ReturnType<typeof useParams>;
  store?: Store;
}

export const WeatherTileLoader = inject("store")(({ params = {}, store, isPrimary, block }: WeatherTileLoaderProps) => {
  if (!store) {
    return null;
  }

  const { site, env } = store;
  // override params to siteconfig value
  const lat = params.geo_latitude || site.sections.general?.geo_latitude || null;
  const lon = params.geo_longitude || site.sections.general?.geo_longitude || null;

  if (lat === null || lon === null) {
    return null;
  }
  const baseUri = env.WEATHER_BASE_URI || "";
  const apiKey = env.WEATHER_API_KEY || "";

  const cacheKey = `weathertileloader-${lat}-${lon}`;
  const tileLoader = true;
  const { tags } = block;
  const css = classnames("component-weather-tile", {
    [`themed-block`]: tags?.includes("display-hints/themed-block"),
  });

  const traffic = lat && lon ? <TrafficTile block={block} /> : null;
  return (
    <Remote
      cacheKey={cacheKey}
      loader={() => getSevenDayForecast({ lat, lon, baseUri, apiKey, tileLoader })}
      showLoading={isPrimary}
    >
      {({ data }) => {
        if (data) {
          return (
            <Container className={css}>
              <div className="weather-traffic-tile">
                <WeatherTile block={block} forecast={data} />
                {traffic}
              </div>
            </Container>
          );
        }
        return traffic;
      }}
    </Remote>
  );
});
export default WeatherTileLoader;
