import * as io from "io-ts";
import {
  MaybeNumberResolver,
  MaybeStringResolver,
  MaybeNumberArrayResolver,
  MaybeStringArrayResolver,
} from "./util.types";

export const CurrentConditionsResolver = io.type({
  cloudCeiling: MaybeNumberResolver,
  cloudCoverPhrase: io.string,
  dayOfWeek: io.string,
  dayOrNight: io.string,
  expirationTimeUtc: io.number,
  iconCode: io.number,
  iconCodeExtend: io.number,
  obsQualifierCode: MaybeStringResolver,
  obsQualifierSeverity: MaybeNumberResolver,
  precip1Hour: MaybeNumberResolver,
  precip6Hour: MaybeNumberResolver,
  precip24Hour: MaybeNumberResolver,
  pressureAltimeter: io.number,
  pressureChange: io.number,
  pressureMeanSeaLevel: io.number,
  pressureTendencyCode: io.number,
  pressureTendencyTrend: io.string,
  relativeHumidity: io.number,
  snow1Hour: MaybeNumberResolver,
  snow6Hour: MaybeNumberResolver,
  snow24Hour: MaybeNumberResolver,
  sunriseTimeLocal: MaybeStringResolver,
  sunriseTimeUtc: MaybeNumberResolver,
  sunsetTimeLocal: MaybeStringResolver,
  sunsetTimeUtc: MaybeNumberResolver,
  temperature: io.number,
  temperatureChange24Hour: io.number,
  temperatureDewPoint: io.number,
  temperatureFeelsLike: io.number,
  temperatureHeatIndex: io.number,
  temperatureMax24Hour: io.number,
  temperatureMaxSince7Am: io.number,
  temperatureMin24Hour: io.number,
  temperatureWindChill: io.number,
  uvDescription: io.string,
  uvIndex: io.number,
  validTimeLocal: io.string,
  validTimeUtc: io.number,
  visibility: io.number,
  windDirection: io.number,
  windDirectionCardinal: io.string,
  windGust: MaybeNumberResolver,
  windSpeed: MaybeNumberResolver,
  wxPhraseLong: io.string,
  wxPhraseMedium: MaybeStringResolver,
  wxPhraseShort: MaybeStringResolver,
});
export type CurrentConditions = io.TypeOf<typeof CurrentConditionsResolver>;

const DaypartResolver = io.type({
  cloudCover: MaybeNumberArrayResolver,
  dayOrNight: MaybeStringArrayResolver,
  daypartName: MaybeStringArrayResolver,
  iconCode: MaybeNumberArrayResolver,
  iconCodeExtend: MaybeNumberArrayResolver,
  narrative: MaybeStringArrayResolver,
  precipChance: MaybeNumberArrayResolver,
  precipType: MaybeStringArrayResolver,
  qpf: MaybeNumberArrayResolver,
  qpfSnow: MaybeNumberArrayResolver,
  qualifierCode: MaybeStringArrayResolver,
  qualifierPhrase: MaybeStringArrayResolver,
  relativeHumidity: MaybeNumberArrayResolver,
  snowRange: MaybeStringArrayResolver,
  temperature: MaybeNumberArrayResolver,
  temperatureHeatIndex: io.array(MaybeNumberResolver),
  temperatureWindChill: io.array(MaybeNumberResolver),
  thunderCategory: MaybeStringArrayResolver,
  thunderIndex: MaybeNumberArrayResolver,
  uvDescription: MaybeStringArrayResolver,
  uvIndex: MaybeNumberArrayResolver,
  windDirection: MaybeNumberArrayResolver,
  windDirectionCardinal: MaybeStringArrayResolver,
  windPhrase: MaybeStringArrayResolver,
  windSpeed: MaybeNumberArrayResolver,
  wxPhraseLong: MaybeStringArrayResolver,
  wxPhraseShort: MaybeStringArrayResolver,
});
export type Daypart = io.TypeOf<typeof DaypartResolver>;

const DaypartListResolver = io.array(DaypartResolver);

export const ForecastResultResolver = io.type({
  dayOfWeek: io.array(
    io.union([
      io.literal("Sunday"),
      io.literal("Monday"),
      io.literal("Tuesday"),
      io.literal("Wednesday"),
      io.literal("Thursday"),
      io.literal("Friday"),
      io.literal("Saturday"),
    ]),
  ),
  expirationTimeUtc: io.array(io.number),
  moonPhase: io.array(io.string),
  moonPhaseCode: io.array(
    io.union([
      io.literal("WNG"),
      io.literal("WXC"),
      io.literal("FQ"),
      io.literal("WNC"),
      io.literal("LQ"),
      io.literal("F"),
      io.literal("WXG"),
      io.literal("N"),
    ]),
  ),
  moonPhaseDay: io.array(io.number),
  moonriseTimeLocal: MaybeStringArrayResolver,
  moonriseTimeUtc: MaybeNumberArrayResolver,
  moonsetTimeLocal: MaybeStringArrayResolver,
  moonsetTimeUtc: MaybeNumberArrayResolver,
  narrative: io.array(io.string),
  qpf: io.array(io.number),
  qpfSnow: io.array(io.number),
  sunriseTimeLocal: MaybeStringArrayResolver,
  sunriseTimeUtc: MaybeNumberArrayResolver,
  sunsetTimeLocal: MaybeStringArrayResolver,
  sunsetTimeUtc: MaybeNumberArrayResolver,
  temperatureMax: MaybeNumberArrayResolver,
  temperatureMin: io.array(io.number),
  validTimeUtc: io.array(io.number),
  validTimeLocal: io.array(io.string),
  calendarDayTemperatureMax: MaybeNumberArrayResolver,
  calendarDayTemperatureMin: MaybeNumberArrayResolver,
  daypart: io.union([DaypartListResolver, io.null]),
});

export type ForecastResult = io.TypeOf<typeof ForecastResultResolver>;
